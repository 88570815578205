import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BoolFilterQueryParam } from '../../models/device';

export type Props = {
  deliveryModeFeatureEnabled: BoolFilterQueryParam | null;
  onChange: (deliveryModeFeatureEnabled: BoolFilterQueryParam | null) => void;
};

export const DeliveryModeFeatureFilter = ({ deliveryModeFeatureEnabled, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel>{t('deviceOverview.deliveryModeFeatureFilter.label')}</InputLabel>
      <Select
        aria-label={t('deviceOverview.deliveryModeFeatureFilter.ariaLabel')}
        label={t('deviceOverview.deliveryModeFeatureFilter.label')}
        onChange={({ target: { value } }) =>
          onChange((value === 'all' ? null : value) as BoolFilterQueryParam | null)
        }
        sx={{ display: 'flex' }}
        value={deliveryModeFeatureEnabled ?? 'all'}
      >
        <MenuItem value={'all'}>{t('deviceOverview.deliveryModeFeatureEnabled.all')}</MenuItem>
        <Divider />
        <MenuItem value={'BOOL_FILTER_TRUE'}>
          {t('deviceOverview.deliveryModeFeatureEnabled.true')}
        </MenuItem>
        <MenuItem value={'BOOL_FILTER_FALSE'}>
          {t('deviceOverview.deliveryModeFeatureEnabled.false')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
