import { commonApiV1 } from '@deepup/apis';
import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';

import type { UsageOrganizationName } from '../models/device';

import { useEnvironment } from './useEnvironment';
import { useErrorTracking } from './useErrorTracking';
import { useJsonFetcher } from './useJsonFetcher';

const errorRetryCount = 2;

const usageOrganizationsPath =
  '/internal-api/device-management/v1/usage-agreements/list-usage-organization-names';

export const useUsageOrganizations = () => {
  const { checkResponse } = useErrorTracking();
  const { apiUrl } = useEnvironment();
  const [retryCount, setRetryCount] = useState<number>(errorRetryCount);
  const [currentPage, setCurrentPage] = useState(0);

  const [isRefetching, setIsRefetching] = useState(false);

  const [totalPages, setTotalPages] = useState(1);
  const [allUsageOrganizationNames, setAllUsageOrganizationNames] = useState<
    UsageOrganizationName[]
  >([]);

  const jsonFetcher = useJsonFetcher<{
    items: UsageOrganizationName[];
    paginationInfo: commonApiV1.PaginationInfo;
  }>({
    method: 'POST',
    body: JSON.stringify({
      options: { page: currentPage, pageSize: 20, withPaginationInfo: true },
    }),
  });

  const requestPath = `${apiUrl}${usageOrganizationsPath}`;

  const response = useSWR([requestPath, currentPage], jsonFetcher, {
    refreshInterval: 60000,
    errorRetryInterval: 1000,
    errorRetryCount,
  });

  checkResponse(response);

  useEffect(
    () =>
      setRetryCount((currentRetryCount) => {
        if (response.error) {
          return currentRetryCount - 1;
        }

        return errorRetryCount;
      }),
    [response.error],
  );

  useEffect(() => {
    if (response.data) {
      const { paginationInfo, items } = response.data;

      const totalPages = Number(paginationInfo.totalPages);

      setTotalPages(totalPages);

      // Move to next page if we haven't reached the end
      if (currentPage < totalPages - 1) {
        setCurrentPage((page) => page + 1);
      }
      if (isRefetching && currentPage === 0) {
        // This useEffect cannot have allUsageOrganizationNames as a dependency
        // because it will cause a loop, when refetching, it has old values
        // and we need to set the new values with the values of the first page.
        setAllUsageOrganizationNames(items);
        setIsRefetching(false);

        return;
      }
      setAllUsageOrganizationNames((prev) => {
        return [...new Set([...prev, ...items])];
      });
    }
  }, [response.data, currentPage, isRefetching]);

  const isLoading = currentPage < totalPages - 1 || response.isLoading;

  return {
    data: { items: [...new Set(allUsageOrganizationNames)] },
    error: response.error,
    isLoading,
    isRetrying: response.error && retryCount > 0,
    refetchUsageOrganizations: () => {
      setAllUsageOrganizationNames([]);
      setIsRefetching(true);
      setCurrentPage(0);
      mutate(
        (key) => Array.isArray(key) && key[0] === `${apiUrl}${usageOrganizationsPath}`,
        undefined,
        {
          revalidate: true,
        },
      );
    },
  };
};
