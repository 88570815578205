import { OfficeBuildingOutline } from '@deepup/icons';
import { useTranslation } from 'react-i18next';

import { UsageAgreement } from 'src/models/device';

import { Section, SubSection } from './CommonSections';

export const UsageOrganizationInfo = ({ usageAgreement }: { usageAgreement: UsageAgreement }) => {
  const { t } = useTranslation();

  const {
    customerName,
    customerCity,
    customerStreet,
    customerContactPerson,
    customerPhone,
    customerMail,
  } = usageAgreement;

  return (
    <Section
      icon={<OfficeBuildingOutline fontSize={23} />}
      title={t('deviceInfo.organizationOfUser')}
    >
      <SubSection content={customerName} title={t('deviceInfo.organization')} />
      <SubSection
        content={`${customerCity}, ${customerStreet}`}
        title={t('deviceOverview.deviceTable.columns.address.header')}
      />
      <SubSection content={customerContactPerson} title={t('deviceInfo.contactPerson')} />
      <SubSection content={customerPhone} title={t('deviceInfo.phone')} />
      <SubSection content={customerMail} title={t('deviceInfo.email')} />
    </Section>
  );
};
