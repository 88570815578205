import { useTranslation } from 'react-i18next';

import { UsageOrganizationName } from 'src/models/device';

import { SearchableSelect } from '../SearchableSelect';

type Props = {
  initialValue: string | null;
  onChange: (selectedOption: string | null) => void;
  items: UsageOrganizationName[];
};

export const UsageOrganizationSearch = ({ onChange, initialValue, items }: Props) => {
  const { t } = useTranslation();

  return (
    <SearchableSelect
      initialValue={initialValue}
      label={t('deviceOverview.usageOrganizationFilter.label')}
      onChange={(value) => {
        onChange(value);
      }}
      options={items}
      placeholder={t('deviceOverview.usageOrganizationFilter.placeholder')}
    />
  );
};
