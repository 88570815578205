import { useAuth0 } from '@auth0/auth0-react';
import { Fetcher } from 'swr';

import { FetchError } from '../types/error';

export type JsonFetcherArgs = [url: string, fetchInit?: RequestInit];

export const useJsonFetcher = <T>(additionalParams?: { [key: string]: string | object }) => {
  const { getAccessTokenSilently } = useAuth0();

  const jsonFetcher: Fetcher<T, JsonFetcherArgs> = async ([url]) => {
    const accessToken = await getAccessTokenSilently();

    const headers = { Authorization: `Bearer ${accessToken}` };

    const response = await fetch(url, { headers, ...additionalParams });

    if (!response.ok) {
      const error = await response.json();

      throw new FetchError(response.status, error.key, error.id);
    }

    return response.json();
  };

  return jsonFetcher;
};
