import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Device } from '../../models/device';
import { ActionOptions } from '../DeliveryModeUpdateDrawer/DeliveryModeUpdateDrawer';

interface FooterProps {
  selectedDevices: Device[];
  devices: Device[];
  setUpdateOption: (option: ActionOptions) => void;
  onCancel: () => void;
}

export const Footer = ({ selectedDevices, devices, setUpdateOption, onCancel }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexGrow={1}
      height="100%"
      justifyContent="space-between"
      minHeight="52px"
      width="100%"
    >
      <Typography fontWeight="fontWeightBold">
        {t('tableFooter.selectedDevices', { count: selectedDevices.length, total: devices.length })}
      </Typography>

      <Box alignItems="center" display="flex" gap={3}>
        <Typography>{t('tableFooter.chooseAction')}</Typography>
        <Button
          color="primary"
          disabled={selectedDevices.length === 0}
          onClick={() => setUpdateOption('endUsageAgreement')}
          variant="outlined"
        >
          {t('tableFooter.endUsageAgreement')}
        </Button>
        <Button
          color="primary"
          disabled={selectedDevices.length === 0}
          onClick={() => setUpdateOption('setDeliveryModeFeature')}
          variant="outlined"
        >
          {t('tableFooter.setDeliveryModeFeature')}
        </Button>

        <Button
          color="secondary"
          disabled={selectedDevices.length === 0}
          onClick={onCancel}
          variant="text"
        >
          {t('tableFooter.cancel')}
        </Button>
      </Box>
    </Box>
  );
};
