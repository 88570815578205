import { styled, Box } from '@mui/material';

import { headerHeightSpacing } from '../components/GlobalHeader';

export const pagePadding = 3;

export const PageContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(pagePadding),
  minHeight: `calc(100vh - ${theme.spacing(headerHeightSpacing)})`,
  display: 'flex',
  justifyContent: 'center',
}));
