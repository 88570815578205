import { SearchOutline } from '@deepup/icons';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';

export type Props = {
  options: string[];
  label: string;
  placeholder: string;
  initialValue: string | null;
  onChange: (selectedOption: string | null) => void;
};

export const SearchableSelect = ({
  options,
  onChange,
  initialValue,
  label,
  placeholder,
}: Props) => {
  const [value, setValue] = useState<string | null>(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const searchField = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutline />
          </InputAdornment>
        ),
      }}
      label={label}
      placeholder={placeholder}
    />
  );

  return (
    <Autocomplete
      defaultValue={initialValue || ''}
      disablePortal
      onChange={(_, selectedOption) => {
        setValue(selectedOption);
        onChange(selectedOption);
      }}
      options={options}
      renderInput={searchField}
      value={value}
    />
  );
};
