import { IdToken, useAuth0 } from '@auth0/auth0-react';
import flagsmith from 'flagsmith';
import { useEffect, useState } from 'react';

export const useIsDeepUpUser = (): boolean => {
  const { getIdTokenClaims } = useAuth0();
  const [isDeepUpUser, setIsDeepUpUser] = useState(false);

  useEffect(() => {
    const getClaims = async () => {
      const claims = await getIdTokenClaims();

      const { sub } = claims as IdToken;

      const isDeepUpUser = sub.startsWith('google-oauth2|');

      setIsDeepUpUser(isDeepUpUser);

      if (isDeepUpUser) {
        await flagsmith.identify(sub, { role: 'deepup' });
      }
    };

    getClaims();
  }, [getIdTokenClaims]);

  return isDeepUpUser;
};
