import { XOutline } from '@deepup/icons';
import { Icon } from '@mui/material';
import { DateTime } from 'luxon';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ISO8601Timestamp } from 'src/types/iso8601Timestamp';

import { Device } from '../../models/device';
import { Spacer } from '../../styles/Spacer';

import { ActivityStatusCell } from './Cells/ActivityStatusCell';
import { Cell } from './Cells/Cell';
import { CellWithInfo } from './Cells/CellWithInfo';

export type TableRow = {
  id: ReactNode;
  serialNumber: ReactNode;
  clientOrganization: ReactNode;
  project: ReactNode;
  address: ReactNode;
};

export const TableRows = (devices: Device[]): TableRow[] => {
  const { t, i18n } = useTranslation();

  const updatedAtInfoString = (translationKey: string, updatedAt: ISO8601Timestamp) =>
    t(translationKey, {
      updatedAt: DateTime.fromISO(updatedAt).toLocaleString(DateTime.DATETIME_MED, {
        locale: i18n.language,
      }),
    });

  return devices.map(
    ({
      id,
      serialNumber: serialNumberProp,
      clientOrganization: clientOrganizationProp,
      latestAsset,
      latestAddress,
      activityStatus: activityStatusProp,
      isInDeliveryMode: isInDeliveryModeProp,
      usageAgreement,
      deliveryModeFeatureEnabled: deliveryModeFeatureEnabledProp,
    }) => {
      const serialNumber = serialNumberProp ? <Cell>{serialNumberProp}</Cell> : null;

      const clientOrganization = clientOrganizationProp ? (
        <Cell>{clientOrganizationProp.name}</Cell>
      ) : null;

      const project = latestAsset?.project ? (
        <CellWithInfo
          info={updatedAtInfoString(
            'deviceOverview.deviceTable.columns.project.info',
            latestAsset.recordedAt,
          )}
        >
          {latestAsset.project.name}
        </CellWithInfo>
      ) : null;

      const address = latestAddress ? (
        <CellWithInfo
          info={updatedAtInfoString(
            'deviceOverview.deviceTable.columns.address.info',
            latestAddress.updatedAt,
          )}
        >
          {latestAddress.city}
          {latestAddress.street.length > 0 ? `, ${latestAddress.street}` : ''}
        </CellWithInfo>
      ) : null;

      const activityStatus = <ActivityStatusCell activityStatus={activityStatusProp} />;
      const isInDeliveryMode = (
        <Cell>{t(`deviceOverview.isInDeliveryMode.${!!isInDeliveryModeProp}`)}</Cell>
      );

      const deliveryModeFeatureEnabled = (
        <Cell>
          {!deliveryModeFeatureEnabledProp && (
            <>
              <Icon sx={{ display: 'flex' }}>
                <XOutline opacity={0.75} />
              </Icon>
              <Spacer width={4} />
            </>
          )}
          {t(`deviceOverview.deliveryModeFeatureEnabled.${!!deliveryModeFeatureEnabledProp}`)}
        </Cell>
      );

      const usageOrganization = <Cell>{usageAgreement?.customerName || ''}</Cell>;

      return {
        id,
        serialNumber,
        clientOrganization,
        project,
        address,
        activityStatus,
        isInDeliveryMode,
        usageOrganization,
        deliveryModeFeatureEnabled,
      };
    },
  );
};
