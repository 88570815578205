import { XOutline } from '@deepup/icons';
import { IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type DeviceInfoHeaderProps = {
  serialNumber: string;
  onClose: () => void;
};

export const DeviceInfoHeader = ({ serialNumber, onClose }: DeviceInfoHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems={'center'} direction={'row'} gap={1} p={1}>
      <IconButton
        aria-label={t('deviceOverview.deviceFilter.clearButton.ariaLabel')}
        onClick={onClose}
      >
        <XOutline />
      </IconButton>
      <Typography component={'div'} fontWeight={'fontWeightBold'} variant="h6">
        {serialNumber}
      </Typography>
    </Stack>
  );
};
