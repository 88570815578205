import { CheckmarkCircleFilled } from '@deepup/icons';
import {
  Alert,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateDevices } from '../../hooks/useUpdateDevices';
import { Device } from '../../models/device';
import { Spacer } from '../../styles/Spacer';
import { HeaderSpace } from '../GlobalHeader';

import { EndUsageAgreement } from './EndUsageAgreement';
import { LoadingSpinner } from './LoadingSpinner';
import { RetryFailedUpdates } from './RetryFailedUpdates';
import { SetDeliveryModeFeature, ValueToUpdate } from './SetDeliveryModeFeature';

interface DeliveryModeChangeProps {
  devices: Device[];
  onSuccessfulUpdate: () => void;
  open: boolean;
  handleClose: () => void;
  currentOption: ActionOptions;
}

export type ActionOptions = 'endUsageAgreement' | 'setDeliveryModeFeature' | null;

const endUsageAgreementKey = ['/internal-api/device-management/v1/usage-agreements/', '/end'];
const setDeliveryModeFeatureKey = [
  '/internal-api/device-management/v0/scanners/',
  '/delivery-mode-feature-state',
];

export const DeliveryModeUpdateDrawer: React.FC<DeliveryModeChangeProps> = ({
  devices,
  onSuccessfulUpdate,
  open,
  handleClose,
  currentOption,
}) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();

  const [rejectionReason, setRejectionReason] = useState('');
  const [valueToUpdate, setValueToUpdate] = useState<ValueToUpdate>(ValueToUpdate.DEFAULT);
  const { isLoading, failedDevices, updateDevices, isUnauthorized } = useUpdateDevices(
    devices,
    currentOption === 'endUsageAgreement' ? endUsageAgreementKey : setDeliveryModeFeatureKey,
    open,
  );
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setAlertTitle] = useState('');

  const getMessageKeys = () => {
    const titleKey =
      currentOption === 'endUsageAgreement'
        ? 'successfullyEndedTitle'
        : `successfullyFeatureChangedTitle.${valueToUpdate}`;

    const messageKey =
      currentOption === 'endUsageAgreement'
        ? 'successfullyEndedDescription'
        : 'successfullyFeatureChangedDescription';

    return { titleKey, messageKey };
  };

  const onUpdateSuccesfull = () => {
    setShowSuccessMessage(true);
    const { titleKey, messageKey } = getMessageKeys();

    setAlertTitle(t(`deliveryModeUpdateDrawer.${titleKey}`));
    setAlertMessage(
      t(`deliveryModeUpdateDrawer.${messageKey}`, {
        count: devices.length,
      }),
    );
    handleClose();
    onSuccessfulUpdate();
    setValueToUpdate(ValueToUpdate.DEFAULT);
    setRejectionReason('');
  };

  const createEndUsageAgreementBody = (device: Device) => {
    const body = {
      usageAgreementId: device?.usageAgreement?.id,
      rejectionReason: rejectionReason,
    };

    return JSON.stringify(body);
  };

  const handleUpdateEndUsageAgreement = async (devicesToUpdate: Device[]) => {
    await updateDevices(
      devicesToUpdate,
      (device) => device?.usageAgreement?.id || '',
      createEndUsageAgreementBody,
      onUpdateSuccesfull,
    );
  };

  const createSetDeliveryModeBody = (device: Device) => {
    const valueToSet = valueToUpdate === ValueToUpdate.YES ? true : false;
    const body = {
      scannerId: device.id,
      deliveryModeFeatureEnabled: valueToSet,
    };

    return JSON.stringify(body);
  };

  const handleUpdateDeliveryModeFeature = async (devicesToUpdate: Device[]) => {
    await updateDevices(
      devicesToUpdate,
      (device: Device) => device.id,
      createSetDeliveryModeBody,
      onUpdateSuccesfull,
    );
  };

  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={6000}
        onClose={() => setShowSuccessMessage(false)}
        open={showSuccessMessage}
      >
        <Alert
          icon={<CheckmarkCircleFilled />}
          onClose={() => setShowSuccessMessage(false)}
          severity="success"
          sx={{
            width: '100%',
            backgroundColor: 'secondary',
            color: 'primary',
            '& .MuiAlert-icon': {
              color: 'primary',
            },
            boxShadow: 'none',
            '& .MuiAlert-action': {
              display: 'none',
            },
          }}
        >
          <Typography fontWeight="bold" variant="subtitle1">
            {alertTitle}
          </Typography>
          <Typography variant="body2">{alertMessage}</Typography>
        </Alert>
      </Snackbar>
      <Drawer
        PaperProps={{
          sx: { width: { xs: '100%', sm: 450 } },
        }}
        anchor="right"
        data-testid="DeliveryModeUpdateDrawer"
        onClose={handleClose}
        open={open}
      >
        <HeaderSpace />
        {!isLoading && failedDevices.length === 0 && devices.length > 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                px: 3,
                py: 1,
              }}
            >
              <Typography fontWeight={'fontWeightBold'} variant="h6">
                {t('deliveryModeUpdateDrawer.title', { count: devices.length })}
              </Typography>
              <IconButton onClick={handleClose} size="small">
                <GridCloseIcon />
              </IconButton>
            </Box>
            <Divider />
            <Spacer height={spacing(1)} />
          </>
        )}

        {isLoading && <LoadingSpinner />}
        {!isLoading && failedDevices.length > 0 && (
          <>
            <Box sx={{ justifyContent: 'right', px: 3, py: 1, display: 'flex' }}>
              <IconButton onClick={handleClose} size="small">
                <GridCloseIcon />
              </IconButton>
            </Box>

            <RetryFailedUpdates
              failedDevices={failedDevices}
              handleUpdate={
                currentOption === 'endUsageAgreement'
                  ? handleUpdateEndUsageAgreement
                  : handleUpdateDeliveryModeFeature
              }
              isUnauthorized={isUnauthorized}
            />
          </>
        )}

        {!isLoading && failedDevices.length === 0 && (
          <Box px={3} py={1}>
            {currentOption === 'endUsageAgreement' && (
              <EndUsageAgreement
                rejectionReason={rejectionReason}
                setRejectionReason={setRejectionReason}
              />
            )}

            {currentOption === 'setDeliveryModeFeature' && (
              <SetDeliveryModeFeature
                setValueToUpdate={setValueToUpdate}
                valueToUpdate={valueToUpdate}
              />
            )}

            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button onClick={handleClose} variant="outlined">
                {t('deliveryModeUpdateDrawer.cancelButton')}
              </Button>
              <Button
                color="primary"
                disabled={
                  currentOption === 'setDeliveryModeFeature' &&
                  valueToUpdate === ValueToUpdate.DEFAULT
                }
                onClick={() =>
                  currentOption === 'endUsageAgreement'
                    ? handleUpdateEndUsageAgreement(devices)
                    : handleUpdateDeliveryModeFeature(devices)
                }
                variant="contained"
              >
                {currentOption === 'endUsageAgreement'
                  ? t('deliveryModeUpdateDrawer.confirmEndButton')
                  : t('deliveryModeUpdateDrawer.updateButton')}
              </Button>
            </Box>
          </Box>
        )}
      </Drawer>
    </Box>
  );
};
