import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Spacer } from '../../styles/Spacer';

export enum ValueToUpdate {
  DEFAULT = 'default',
  YES = 'true',
  NO = 'false',
}

interface SetDeliveryModeFeatureProps {
  valueToUpdate: ValueToUpdate;
  setValueToUpdate: (valueToUpdate: ValueToUpdate) => void;
}

export const SetDeliveryModeFeature: React.FC<SetDeliveryModeFeatureProps> = ({
  valueToUpdate,
  setValueToUpdate,
}) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();

  return (
    <Box component="div">
      <FormControl fullWidth>
        <Typography variant="h6">
          {t('deliveryModeUpdateDrawer.setDeliveryModeFeatureOption')}
        </Typography>
        <Spacer height={spacing(2)} />
        <RadioGroup
          aria-label={t('deviceOverview.deliveryModeFeatureFilter.label')}
          onChange={(e) => setValueToUpdate(e.target.value as ValueToUpdate)}
          sx={{ mb: 2 }}
          value={valueToUpdate}
        >
          <FormControlLabel
            control={<Radio />}
            label={t(`deviceOverview.deliveryModeFeatureEnabled.true`)}
            value={ValueToUpdate.YES}
          />
          <FormControlLabel
            control={<Radio />}
            label={t(`deviceOverview.deliveryModeFeatureEnabled.false`)}
            value={ValueToUpdate.NO}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
