import { ArrowsCircle1Ccw } from '@deepup/icons';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ErrorGeneric from '../../assets/errorGeneric.svg?react';
import ErrorPermission from '../../assets/errorPermission.svg?react';
import { Device } from '../../models/device';

export const RetryFailedUpdates = ({
  failedDevices,
  handleUpdate,
  isUnauthorized,
}: {
  failedDevices: Device[];
  handleUpdate: (devices: Device[]) => void;
  isUnauthorized: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Box p={3}>
      <Box sx={{ textAlign: 'left', gap: 2, display: 'flex', flexDirection: 'column' }}>
        {isUnauthorized ? (
          <ErrorPermission style={{ margin: 'auto' }} />
        ) : (
          <ErrorGeneric style={{ margin: 'auto' }} />
        )}
        <Typography variant="h6">
          {isUnauthorized
            ? t('deliveryModeUpdateDrawer.noPermissionTitle')
            : t('deliveryModeUpdateDrawer.serverErrorTitle')}
        </Typography>
        <Typography>
          {isUnauthorized
            ? t('deliveryModeUpdateDrawer.noPermissionText')
            : t('deliveryModeUpdateDrawer.serverErrorText')}
        </Typography>
      </Box>
      {!isUnauthorized && (
        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button
            color="primary"
            onClick={() => handleUpdate(failedDevices)}
            sx={{ display: 'flex', gap: 1 }}
            variant="contained"
          >
            <ArrowsCircle1Ccw></ArrowsCircle1Ccw>
            {t('deliveryModeUpdateDrawer.tryAgain')}
          </Button>
        </Box>
      )}
    </Box>
  );
};
