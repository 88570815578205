import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useFlags } from 'flagsmith/react';
import { ReactNode } from 'react';

import { headerHeightSpacing } from '../../components/GlobalHeader';
import { useIsScanDeviceAdmin } from '../../hooks/useIsScanDeviceAdmin';
import { pagePadding } from '../../styles/PageContainer';
import { Spacer } from '../../styles/Spacer';

export type Props = {
  heading: string;
  serialNumberSearch: ReactNode;
  activityStatusFilter: ReactNode;
  isInDeliveryModeFilter: ReactNode;
  deliveryModeFeatureFilter: ReactNode;
  deviceTable: ReactNode;
  clientSearch: ReactNode;
  usageOrganizationSearch: ReactNode;
  projectSearch: ReactNode;
  sidebar: ReactNode;
};

export const Layout = ({
  heading,
  serialNumberSearch,
  activityStatusFilter,
  isInDeliveryModeFilter,
  deliveryModeFeatureFilter,
  deviceTable,
  clientSearch,
  usageOrganizationSearch,
  projectSearch,
  sidebar,
}: Props) => {
  const { spacing, typography } = useTheme();

  const isScanDeviceAdmin = useIsScanDeviceAdmin();
  const { delivery_mode_enabled } = useFlags(['delivery_mode_enabled']);

  const isDeliveryModeEnabled = delivery_mode_enabled.enabled;

  return (
    <Stack
      sx={{
        width: '100%',
        flexDirection: 'row',
        height: `calc(100vh - ${spacing(headerHeightSpacing + pagePadding * 2)})`,
      }}
    >
      <Spacer width={spacing(5)} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Typography sx={{ fontSize: spacing(4), fontWeight: typography.fontWeightBold }}>
          {heading}
        </Typography>
        <Spacer height={spacing(3)} />
        <Grid container spacing={spacing(2)}>
          <Grid item md={3} sm={3} xs={12}>
            {serialNumberSearch}
          </Grid>
          {isScanDeviceAdmin && (
            <Grid item md={3} sm={3} xs={12}>
              {clientSearch}
            </Grid>
          )}
          <Grid item md={3} sm={3} xs={12}>
            {projectSearch}
          </Grid>

          {isDeliveryModeEnabled && (
            <Grid item md={3} sm={3} xs={12}>
              {usageOrganizationSearch}
            </Grid>
          )}

          <Grid item md={3} sm={3} xs={12}>
            {activityStatusFilter}
          </Grid>
          {isDeliveryModeEnabled && (
            <Grid item md={3} sm={3} xs={12}>
              {isInDeliveryModeFilter}
            </Grid>
          )}
          {isScanDeviceAdmin && isDeliveryModeEnabled && (
            <Grid item md={3} sm={3} xs={12}>
              {deliveryModeFeatureFilter}
            </Grid>
          )}
        </Grid>
        <Spacer height={spacing(3)} />
        {deviceTable}
        {sidebar}
      </Box>
      <Spacer width={spacing(5)} />
    </Stack>
  );
};
