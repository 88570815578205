import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

import { Spacer } from '../../styles/Spacer';

interface EndUsageAgreementProps {
  rejectionReason: string;
  setRejectionReason: (reason: string) => void;
}

export const EndUsageAgreement = ({
  rejectionReason,
  setRejectionReason,
}: EndUsageAgreementProps) => {
  const { t } = useTranslation();
  const { spacing } = useTheme();

  return (
    <Box>
      <Typography variant="h6">{t('deliveryModeUpdateDrawer.endUsageAgreementButton')}</Typography>
      <Spacer height={spacing(2)} />
      <TextField
        InputLabelProps={{ shrink: true }}
        fullWidth
        label={t('deliveryModeUpdateDrawer.rejectionReason')}
        multiline
        onChange={(e) => setRejectionReason(e.target.value)}
        placeholder={t('deliveryModeUpdateDrawer.rejectionReasonPlaceholder')}
        rows={4}
        sx={{ mb: 2 }}
        value={rejectionReason}
        variant="outlined"
      />
    </Box>
  );
};
