import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type SubSectionProps = {
  title: string;
  content: string;
  icon?: ReactNode;
};

export const SubSection = ({ title, content, icon }: SubSectionProps) => (
  <Box alignItems="center" display="flex" sx={{ justifyContent: 'space-between' }}>
    <Box>
      <Typography variant="subtitle2">{title}</Typography>
      <Typography variant="body1">{content}</Typography>
    </Box>
    {icon && <Box ml={1}>{icon}</Box>}
  </Box>
);

type SectionProps = {
  title: string;
  icon?: ReactNode;
  children?: ReactNode;
};

export const Section = ({ title, icon, children }: SectionProps) => (
  <Stack gap={2} p={2}>
    <Box alignItems={'center'} display={'flex'} gap={1}>
      {icon}
      <Typography component={'div'} fontWeight={'fontWeightBold'} variant="h6">
        {title}
      </Typography>
    </Box>
    {children}
  </Stack>
);
