import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import useSWRMutation from 'swr/mutation';

import { Device } from '../models/device';

import { useEnvironment } from './useEnvironment';

const updateDevice = async (
  url: string[],
  { arg }: { arg: { body: string; device: Device; accessToken: string; id: string } },
): Promise<DeviceUpdateResponse> => {
  const [apiUrl, startPath, endPath] = url;
  const { body, device, accessToken, id } = arg;
  const headers = { Authorization: `Bearer ${accessToken}` };
  const fullPath = `${apiUrl}${startPath}${id}${endPath}`;

  const response = await fetch(fullPath, {
    headers,
    method: 'PUT',
    body,
  });

  return {
    device,
    responseStatus: response.status,
  };
};

export const useUpdateDevices = (
  devicesToUpdate: Device[],
  pathKey: string[],
  isDrawerOpen: boolean,
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const [failedDevices, setFailedDevices] = useState<Device[]>([]);
  const { getAccessTokenSilently } = useAuth0();
  const { apiUrl } = useEnvironment();
  const { trigger } = useSWRMutation([apiUrl, ...pathKey], updateDevice);

  useEffect(() => {
    setFailedDevices([]);
    setIsUnauthorized(false);
  }, [devicesToUpdate, isDrawerOpen]);

  const updateDevices = async (
    devicesToUpdate: Device[],
    getId: (device: Device) => string,
    createBody: (device: Device) => string,
    onSuccess: () => void,
  ) => {
    setIsLoading(true);
    const accessToken = await getAccessTokenSilently();
    const updatePromises = devicesToUpdate.map((device) => {
      return trigger({
        body: createBody(device),
        device,
        id: getId(device),
        accessToken,
      });
    });

    const updateResults = await Promise.allSettled(updatePromises);
    // Promise will never reject, if it fails it will have {responseStatus: number}, therefore the user needs to retry
    const devicesFailedToUpdate = updateResults
      .filter((r) => r.status === 'fulfilled' && r.value?.responseStatus !== 200)
      .map((r) => (r as PromiseFulfilledResult<DeviceUpdateResponse>).value.device);

    const isAnyResponeUnauthorized = updateResults.find(
      (r) => r.status === 'fulfilled' && r.value.responseStatus === 403,
    );

    if (isAnyResponeUnauthorized) {
      setIsUnauthorized(true);
    }

    setFailedDevices(devicesFailedToUpdate);

    setIsLoading(false);
    if (devicesFailedToUpdate.length === 0) {
      onSuccess();
    }
  };

  return { isLoading, failedDevices, updateDevices, isUnauthorized };
};

export type DeviceUpdateResponse = {
  device: Device;
  responseStatus: number;
};
