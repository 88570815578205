import { useSearchParams } from 'react-router-dom';

import type {
  TablePaginationModel,
  TableSortDirection,
  TableSortModel,
} from '../components/DeviceTable';

export type FilterModel = {
  client: string[];
  project: string[];
  serialNumber: string[];
  activityStatus: string | null;
  usageOrganizationName: string | null;
  isInDeliveryMode: string | null;
  deliveryModeFeatureEnabled: string | null;
};

type QueryParams = {
  sortModel: TableSortModel;
  setSortModel: (sortModel: TableSortModel) => void;
  paginationModel: TablePaginationModel;
  setPaginationModel: (paginationModel: TablePaginationModel) => void;
  filterModel: FilterModel;
  setFilterModel: (filterModel: FilterModel) => void;
};

export const useQueryParams = (): QueryParams => {
  const initialSortModel = { field: 'project', sort: 'asc' };
  const initialPaginationModel = { page: 0, pageSize: 50 };
  const initialFilterModel = {
    serialNumber: [],
    activityStatus: null,
    client: [],
    project: [],
    usageOrganizationName: null,
    isInDeliveryMode: null,
    deliveryModeFeatureEnabled: null,
  } as FilterModel;

  const [currentSearchParams, setCurrentSearchParams] = useSearchParams();

  const paginationModel = {
    page: Number(currentSearchParams.get('page') ?? initialPaginationModel.page),
    pageSize: Number(currentSearchParams.get('size') ?? initialPaginationModel.pageSize),
  };

  const setPaginationModel = (paginationModel: TablePaginationModel) => {
    const nextSearchParams: URLSearchParams = new URLSearchParams(currentSearchParams.toString());

    nextSearchParams.set('page', paginationModel.page.toString());
    nextSearchParams.set('size', paginationModel.pageSize.toString());
    if (!currentSearchParams.get('sort')) {
      nextSearchParams.set('sort', `${initialSortModel.field},${initialSortModel.sort}`);
    }

    setCurrentSearchParams(nextSearchParams.toString(), { replace: false });
  };

  const sortModel = {
    field: currentSearchParams.get('sort')?.split(',')[0] || initialSortModel.field,
    sort: (currentSearchParams.get('sort')?.split(',')[1] ||
      initialSortModel.sort) as TableSortDirection,
  };

  const setSortModel = (sortModel: TableSortModel) => {
    const nextSearchParams: URLSearchParams = new URLSearchParams(currentSearchParams.toString());

    nextSearchParams.set('sort', `${sortModel.field},${sortModel.sort}`);
    setCurrentSearchParams(nextSearchParams.toString(), { replace: false });
  };

  const filterModel = {
    serialNumber:
      currentSearchParams.get('serialNumber')?.split('+') || initialFilterModel.serialNumber,
    client: currentSearchParams.get('client')?.split('+') || initialFilterModel.client,
    project: currentSearchParams.get('project')?.split('+') || initialFilterModel.project,
    activityStatus: currentSearchParams.get('activityStatus'),
    isInDeliveryMode: currentSearchParams.get('isInDeliveryMode'),
    deliveryModeFeatureEnabled: currentSearchParams.get('deliveryModeFeatureEnabled'),
    usageOrganizationName:
      currentSearchParams.get('usageOrganizationName')?.split('+').join(' ') ||
      initialFilterModel.usageOrganizationName,
  } as FilterModel;

  const setFilterModel = (filterModel: FilterModel) => {
    const nextSearchParams: URLSearchParams = new URLSearchParams(currentSearchParams.toString());

    // reset pagination to page 0 when filtering
    nextSearchParams.set('page', '0');

    if (filterModel.serialNumber.length === 0) {
      nextSearchParams.delete('serialNumber');
    } else {
      nextSearchParams.set('serialNumber', filterModel.serialNumber.join(' '));
    }

    if (filterModel.client.length === 0) {
      nextSearchParams.delete('client');
    } else {
      nextSearchParams.set('client', filterModel.client.join(' '));
    }

    if (filterModel.usageOrganizationName === null) {
      nextSearchParams.delete('usageOrganizationName');
    } else {
      nextSearchParams.set('usageOrganizationName', filterModel.usageOrganizationName);
    }

    if (filterModel.project.length === 0) {
      nextSearchParams.delete('project');
    } else {
      nextSearchParams.set('project', filterModel.project.join(' '));
    }

    if (!filterModel.activityStatus) {
      nextSearchParams.delete('activityStatus');
    } else {
      nextSearchParams.set('activityStatus', filterModel.activityStatus);
    }

    if (filterModel.isInDeliveryMode === null) {
      nextSearchParams.delete('isInDeliveryMode');
    } else {
      nextSearchParams.set('isInDeliveryMode', filterModel.isInDeliveryMode);
    }

    if (filterModel.deliveryModeFeatureEnabled === null) {
      nextSearchParams.delete('deliveryModeFeatureEnabled');
    } else {
      nextSearchParams.set('deliveryModeFeatureEnabled', filterModel.deliveryModeFeatureEnabled);
    }

    setCurrentSearchParams(nextSearchParams.toString(), { replace: false });
  };

  return {
    paginationModel,
    sortModel,
    setPaginationModel,
    setSortModel,
    filterModel,
    setFilterModel,
  };
};
