import { Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BoolFilterQueryParam } from '../../models/device';

export type Props = {
  isInDeliveryMode: BoolFilterQueryParam | null;
  onChange: (isInDeliveryMode: BoolFilterQueryParam | null) => void;
};

export const IsInDeliveryModeFilter = ({ isInDeliveryMode, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel>{t('deviceOverview.isInDeliveryModeFilter.label')}</InputLabel>
      <Select
        aria-label={t('deviceOverview.isInDeliveryModeFilter.ariaLabel')}
        label={t('deviceOverview.isInDeliveryModeFilter.label')}
        onChange={({ target: { value } }) =>
          onChange((value === 'all' ? null : value) as BoolFilterQueryParam | null)
        }
        sx={{ display: 'flex' }}
        value={isInDeliveryMode ?? 'all'}
      >
        <MenuItem value={'all'}>{t(`deviceOverview.isInDeliveryMode.all`)}</MenuItem>
        <Divider />
        <MenuItem value={'BOOL_FILTER_TRUE'}>{t(`deviceOverview.isInDeliveryMode.true`)}</MenuItem>
        <MenuItem value={'BOOL_FILTER_FALSE'}>
          {t(`deviceOverview.isInDeliveryMode.false`)}
        </MenuItem>
      </Select>
    </FormControl>
  );
};
